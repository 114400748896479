import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TranslationOperations } from '../operations/translation.operations';
import { IMachineTranslationInput, IMachineTranslationLanguageCode, IMachineTranslationResponse } from '../interfaces/IMachineTranslation';
import { Observable, from, switchMap, of, catchError, map, concat, reduce, throwError } from 'rxjs';
import { MYG_LANGUAGE_CODE } from '../enums/language-code.enum';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class MachineTranslationService {
  private textTranslationLengthLimit: number = 20000;

  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
  ) {}

  public translate(from: MYG_LANGUAGE_CODE, to: MYG_LANGUAGE_CODE, text: string): Observable<string> {
    if (!text || text === '') {
      const errorMsg = 'No text was passed to automatic translation.';
      console.error(errorMsg);
      return throwError(() => new Error(errorMsg));
    }

    const data: IMachineTranslationInput = {
      FromCode: TranslationOperations.mapMygLanguageTranslationCode(from),
      ToCode: TranslationOperations.mapMygLanguageTranslationCode(to),
      TextContentType: 'text/html',
      Text: text,
      glossary: this._getGlossaryId(to),
    };

    if (!data.FromCode || !data.ToCode) {
      const errorMsg = `Either country code from (${from}|${data.FromCode}) or to (${to}|${data.ToCode}) was not found.`;
      console.error(errorMsg);
      return throwError(() => new Error(errorMsg));
    }

    if (text.length > this.textTranslationLengthLimit) {
      return this._translation(data).pipe(
        map(res => res.Text),
        catchError(error => {
          console.error(error);
          return of('');
        }),
      );
    } else {
      const translationObservables: Observable<string>[] = [];
      let currentPos = 0;

      while (currentPos < text.length) {
        let endPos = Math.min(currentPos + this.textTranslationLengthLimit, text.length);
        let newTextPart = text.substring(currentPos, endPos);

        // Adjust endPos to avoid cutting off HTML tags
        if (endPos < text.length) {
          for (let i = newTextPart.length - 1; i >= 0; i--) {
            if (newTextPart[i] === '>') {
              const tagCheck = newTextPart.substring(0, i).split('<').pop();
              if (TranslationOperations.translationValidToCutTag(tagCheck)) {
                endPos = currentPos + i + 1;
                newTextPart = text.substring(currentPos, endPos);
                break;
              }
            }
          }
        }

        currentPos = endPos;
        if (newTextPart.length > 0) {
          translationObservables.push(
            this._translation({ ...data, Text: newTextPart }).pipe(
              map(res => {
                const lastTag = newTextPart.split('<').pop().split('>')[0];
                if (TranslationOperations.translationValidToReduceTag(lastTag) && !res.Text.endsWith(`</${lastTag}>`)) {
                  res.Text = res.Text.substring(0, res.Text.lastIndexOf(`<${lastTag}>`) + `<${lastTag}>`.length);
                }
                return res.Text;
              }),
              catchError(error => {
                console.error(error);
                return of('');
              }),
            ),
          );
        }
      }

      return concat(...translationObservables).pipe(
        reduce((acc, translatedPart) => acc + translatedPart, ''),
        map(allNewText => TranslationOperations.decodeUnicodeCharacters(allNewText)),
      );
    }
  }

  private _translation(data: IMachineTranslationInput): Observable<IMachineTranslationResponse> {
    return from(this._getHeaders()).pipe(
      switchMap(headers =>
        this.http.post<IMachineTranslationResponse>(
          `${environment.translationServiceUrl}?formality=${data.ToCode === IMachineTranslationLanguageCode.ZH ? 'more' : 'less'}${data.glossary ? `&glossaryId=${data.glossary}` : ''}`,
          data,
          {
            headers,
          },
        ),
      ),
    );
  }

  private _getHeaders(): Observable<HttpHeaders> {
    return of(
      new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'text/plain',
        timeout: '120000',
        Authorization: 'Bearer ' + environment.translationToken,
      }),
    );
  }

  private _getGlossaryId(toLangCode: MYG_LANGUAGE_CODE): string {
    const currentLang = this.translateService.currentLang;
    this.translateService.use(MYG_LANGUAGE_CODE.EN);
    const translation = this.translateService.instant(`admin.translations.glossaries.glossary-${toLangCode}`);
    this.translateService.use(currentLang);
    return translation;
  }
}
