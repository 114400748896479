<div class="wizard-sidenav">
  <ul class="navigation">
    @if (homeNavigation) {
      <li>
        <button class="sidenav-item btn-inline" (click)="navigateTo()">
          <div>
            <div class="img-container">
              @if (homeNavigationImg) {
                <img [src]="homeNavigationImg" alt="Home" />
              }
            </div>
            <div class="strong" [innerHTML]="homeNavigationLabel | translate"></div>
          </div>
        </button>
      </li>
    }
    @for (category of sideNav; track category) {
      @if (!category.hiddenInNavigation) {
        <li>
          <button
            class="sidenav-item btn-inline text-align-start"
            [class.active]="category.id === activeCategory"
            [class.done]="category.completed"
            [class.disabled]="!allNavigationAllowed && !category.completed && category.id !== activeCategory && category.progress === 0"
            [class.cickable]="clickable && category.progress"
            (click)="navigateTo(category)"
          >
            <div>
              <div class="img-container" [class.active]="category.id === activeCategory">
                @if (imgPrefix) {
                  <img [src]="imgPrefix + category.id + '.svg'" alt="Module icon" />
                } @else if (imgPaths) {
                  <img [src]="imgPaths[category.id]" alt="Module icon" />
                }
                @if (category.completed) {
                  <div class="nav-badge-container">
                    <gt-wizard-completion-badge [progress]="100"></gt-wizard-completion-badge>
                  </div>
                }
              </div>
              <div class="strong" [innerHTML]="translationStringPrefix + category.id | translate"></div>
            </div>

            @if (category.id === activeCategory && showSubSteps) {
              <ul class="sub-items">
                @for (sub of category.sub; track sub) {
                  <li>
                    <div class="sidenav-subitem" [class.active]="activeStep === sub.id" [class.disabled]="sub.id > activeStep">
                      {{ translationStringPrefix + "sub." + sub.id | translate }}
                    </div>
                  </li>
                }
              </ul>
            }
          </button>
        </li>
      }
    }
  </ul>
  <!-- <gt-progress [status]="status"></gt-progress> -->
</div>
