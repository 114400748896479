import { POPUP_TYPE } from '../enums/popup_type.enum';
import { IPopupConfig } from '../interfaces/IPopupConfig';
import { environment } from '../../../environments/environment';

export const popUpConfigList: IPopupConfig[] = [
  {
    startDate: new Date('2024-06-17'),
    expirationDate: new Date('2024-06-21'),
    videoId: 'Self_Reflection_Focus_Week',
    type: POPUP_TYPE.focus_week_popup,
    language: ['de', 'en', 'es', 'zh', 'pl'],
    translationKey: 'focusWeekPopup',
  },
  {
    startDate: new Date('2023-06-01'),
    expirationDate: new Date('2024-07-31'),
    videoId: 'Make_Growth_Talks_an_Everyday_Practice',
    type: POPUP_TYPE.growth_talks_popup,
    language: ['de', 'en'],
    translationKey: 'growthTalksVideo',
  },
  {
    startDate: null,
    expirationDate: new Date('2024-03-28'),
    videoId: 'Siemens_MyGrowth_SGES_Video',
    type: POPUP_TYPE.growth_talks_popup,
    language: ['de', 'en'],
    translationKey: 'growthTalksVideo',
  },
  {
    startDate: environment.production ? new Date('2024-10-07') : null, // show on dev immediately for test
    expirationDate: new Date('2024-11-08'),
    videoId: 'Create_a_safe_environment_for_growth_talks',
    type: POPUP_TYPE.growth_talks_popup,
    language: ['de', 'en'],
    translationKey: 'growthTalksVideo.Create_a_safe_environment_for_growth_talks',
  },
  {
    startDate: environment.production ? new Date('2025-02-10') : null,
    expirationDate: new Date('2025-03-07'),
    videoId: 'SIEMENS_Growth_Talk_Episode_IV',
    type: POPUP_TYPE.growth_talks_popup,
    language: ['de', 'en'],
    translationKey: 'growthTalksVideo.SIEMENS_Growth_Talk_Episode_IV',
  },
];
