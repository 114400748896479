import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IDownloadFilePayload, IDownloadItemNew, ISingleDownloadItem } from '../interfaces/IDownloadItem';
import { DownloadMapping, DownloadMappingItem } from 'src/assets/documents/downloadsMapping';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { encode } from 'punycode';
import { Observable, tap } from 'rxjs';
import html2pdf from 'html2pdf.js';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class DownloadsService {
  constructor(
    public translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private snackbar: MatSnackBar,
  ) {}

  getDownloadsOfLanguageAndCategory(languageCode: string, categoryId: string): Promise<IDownloadItemNew[]> {
    return this.http.get<IDownloadItemNew[]>(environment.backendUrl + 'downloads/' + languageCode + '/category/' + categoryId).toPromise();
  }

  getDownloadsOfLanguage(languageCode: string): Promise<IDownloadItemNew[]> {
    return this.http.get<IDownloadItemNew[]>(environment.backendUrl + 'downloads/' + languageCode).toPromise();
  }

  getDownloadsOfLanguageInNestedFormat(languageCode: string): Promise<IDownloadItemNew[]> {
    return this.http.get<IDownloadItemNew[]>(environment.backendUrl + 'downloads/' + languageCode).toPromise();
  }

  getDownloadItemById(languageCode: string, id: string): Promise<IDownloadItemNew> {
    return this.http.get<IDownloadItemNew>(environment.backendUrl + 'downloads/' + languageCode + '/item?id=' + id).toPromise();
  }

  getDownloadItemsByTrackingId(languageCode: string, trackingId: string): Promise<IDownloadItemNew[]> {
    return this.http
      .get<IDownloadItemNew[]>(environment.backendUrl + 'downloads/' + languageCode + '/item?trackingId=' + encodeURIComponent(trackingId))
      .toPromise();
  }

  getSingleDownloadItemById(languageCode: string, id: string): Promise<ISingleDownloadItem> {
    return this.http.get<ISingleDownloadItem>(environment.backendUrl + 'downloads/singleDownload/' + languageCode + '?id=' + id).toPromise();
  }

  getSingleDownloadItemByTrackingId(languageCode: string, trackingId: string): Promise<ISingleDownloadItem> {
    return this.http
      .get<ISingleDownloadItem>(environment.backendUrl + 'downloads/singleDownload/' + languageCode + '?trackingId=' + encodeURIComponent(trackingId))
      .toPromise();
  }

  getFileFromCDN(key: string): Observable<Blob> {
    return this.http.get(environment.filesCdnUrl + key, { responseType: 'blob' });
  }

  createDownload(body: IDownloadItemNew): Promise<IDownloadItemNew> {
    return this.http.post<IDownloadItemNew>(environment.backendUrl + 'downloads', body).toPromise();
  }

  saveDownload(body: IDownloadItemNew): Promise<IDownloadItemNew> {
    return this.http.put<IDownloadItemNew>(environment.backendUrl + 'downloads', body).toPromise();
  }

  deleteDownload(languageCode: string, id: string): Promise<any> {
    return this.http.delete(environment.backendUrl + 'downloads/' + languageCode + '/' + id).toPromise();
  }

  listDownloadFilesForDownload(query: IDownloadFilePayload): Promise<string[]> {
    return this.http
      .get<string[]>(environment.backendUrl + 'downloads/file/list', { params: { language: query.language, downloadId: query.downloadId, type: query.type } })
      .toPromise();
  }

  async getDownloadFile(key: string): Promise<string> {
    key = this.encodeKey(key);
    return this.http.get(environment.backendUrl + 'downloads/file/download/' + key, { responseType: 'text' }).toPromise();
  }

  async uploadDownloadFiles(downloadFile: IDownloadFilePayload[]): Promise<void[]> {
    const uploadPromises = downloadFile.map(async file => {
      const uploadUrl = await this.http
        .get(environment.backendUrl + 'downloads/file/upload', {
          params: { language: file.language, downloadId: file.downloadId, type: file.type, fileName: file.file.name },
          responseType: 'text',
        })
        .toPromise();
      await this.http.put(uploadUrl, file.file, { headers: { 'Content-Type': file.file.type } }).toPromise();
    });
    return Promise.all(uploadPromises);
  }

  deleteDownloadFiles(keys: string[]): Promise<Object[]> {
    const deletePromises = keys.map(async key => {
      key = this.encodeKey(key);
      return this.http.delete(environment.backendUrl + 'downloads/file/' + key).toPromise();
    });
    return Promise.all(deletePromises);
  }

  async deleteDownloadWithFiles(languageCode: string, downloadId: string): Promise<void> {
    try {
      const downloadFileDeletePromises = [];
      downloadFileDeletePromises.push(
        this.listDownloadFilesForDownload({
          language: languageCode,
          downloadId: downloadId,
          type: 'attachment',
        }).then(async attachmentResult => await this.deleteDownloadFiles(attachmentResult.map(attachment => attachment))),
      );
      downloadFileDeletePromises.push(
        this.listDownloadFilesForDownload({
          language: languageCode,
          downloadId: downloadId,
          type: 'thumbnail',
        }).then(async thumbnailResult => await this.deleteDownloadFiles(thumbnailResult.map(thumbnail => thumbnail))),
      );
      await Promise.all(downloadFileDeletePromises).then(async () => {
        await this.deleteDownload(languageCode, downloadId);
      });
    } catch (err) {}
  }

  public checkIfAssetMapped(inputAssetId: string, language: string): DownloadMappingItem[] {
    inputAssetId = this.cleanAssetId(inputAssetId);
    return DownloadMapping.filter(
      item =>
        item.assetId?.toLocaleLowerCase().includes(inputAssetId.toLocaleLowerCase()) &&
        item.languageCode?.toLocaleLowerCase().includes(language.toLocaleLowerCase()),
    );
  }

  public downloadSinglePdf(filename: string): void {
    console.log(filename);
    const lang = this.translate.currentLang;
    this.getFileFromCDN(`downloads/${lang}/singleDownloads/${filename}.pdf`)
      .pipe(
        tap({
          next: blob => {
            const url = window.URL.createObjectURL(blob);
            this.triggerDownload(`${filename}.pdf`, url);
          },
          error: error => {
            console.error('Error on downloading asset:', error);
            this.snackbar.open('Error on downloading document: ' + filename + '.pdf', '', { duration: 5000 });
          },
        }),
      )
      .subscribe();
  }

  public triggerDownload(fileName: string, url: string) {
    const aElem = document.createElement('a');
    aElem.setAttribute('href', url);
    aElem.setAttribute('download', fileName);
    document.body.appendChild(aElem); // Required for FF
    aElem.click();
    document.body.removeChild(aElem);
  }

  public downloadEthicalPrinciples(event: Event): void {
    const target = event.target as HTMLElement;
    if (target.classList.contains('principles-download-link')) {
      this.downloadSinglePdf('ESR_Ethical_Principles');
    }
  }

  private cleanAssetId(assetId: string): string {
    let cleanId = assetId.replaceAll('%20', ' ');
    if (cleanId.includes('%23')) {
      cleanId = cleanId.split('%23')[0];
    }
    return cleanId;
  }

  public isUUID(str: string): boolean {
    const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
    return uuidRegex.test(str);
  }

  async getTrackingIDOfDownload(assetId: string): Promise<string> {
    const language =
      (this.translate.store.currentLang ?? this.activatedRoute.snapshot.queryParams.lang)
        ? (this.translate.store.currentLang ?? this.activatedRoute.snapshot.queryParams.lang)
        : 'en';
    if (!this.checkIfAssetMapped(assetId, language).length && !this.isUUID(assetId)) {
      return assetId;
    } else {
      const downloadMappingItem = this.checkIfAssetMapped(assetId, language);
      if (downloadMappingItem.length) {
        assetId = downloadMappingItem[0].downloadId;
      }
    }
    const downloadItem = await this.getDownloadItemById(language, assetId);
    return downloadItem.trackingId;
  }

  private encodeKey(key: string): string {
    if (environment.backendUrl.includes('://localhost:')) {
      // When local, needs to be 1 time encoded
      return encodeURIComponent(key);
    } else {
      // When not local, needs to be 3 times encoded due to API gateway
      return encodeURIComponent(encodeURIComponent(encodeURIComponent(key)));
    }
  }

  public downloadComponentOnScreen(fileName: string): Observable<void> {
    return new Observable(observer => {
      let data = document.getElementById('contentToConvert');
      let options = {
        margin: [0.5, 0],
        filename: fileName,
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        enableLinks: true,
        image: { type: 'jpeg', quality: 1 },
        html2canvas: {
          dpi: 192,
          scale: 4,
          letterRendering: true,
          useCORS: true,
        },
        pagebreak: {
          mode: ['avoid-all', 'css', 'legacy'],
        },
      };
      html2pdf()
        .set(options)
        .from(data)
        .save()
        .then(() => {
          observer.next();
          observer.complete();
        })
        .catch(error => {
          observer.error(error);
        });
    });
  }
}
