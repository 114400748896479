import { IEsrjCompletedStep } from '../../interfaces/IEsrjCompeltedStep';
import { JourneyMultipleChoiceItem } from '../../interfaces/IJourneyMultipleChoice';
import { JourneyRatingData } from '../../interfaces/IJourneyRating';
import { IEthicalContrast, IEthicalGoal, IEthicalInfluence, IEthicalIntention, IUserOwnDilemmaInput } from '../../interfaces/IUserInput';

export class UpdateESRJCompletedSteps {
  static readonly type = '[EthicalSelfReflectionJourney] Update Completed Steps';
  constructor(public newStep: IEsrjCompletedStep) {}
}

export class UpdateESRJAspectsOfEthics {
  static readonly type = '[EthicalSelfReflectionJourney] Update Selected Aspects Of Ethics';
  constructor(public aspectsOfEthics: JourneyMultipleChoiceItem[]) {}
}

export class UpdateESRJCompletedAspectsOfEthics {
  static readonly type = '[EthicalSelfReflectionJourney] Update Completed Aspects Of Ethics';
  constructor(public completedAspectsOfEthics: Set<string>) {}
}

export class UpdateESRJEthicalSelectedDilemma {
  static readonly type = '[EthicalSelfReflectionJourney] Update Selected Dilemma';
  constructor(public ethicalSelectedDilemma: JourneyMultipleChoiceItem[]) {}
}

export class UpdateESRJCompletedSelectedDilemmas {
  static readonly type = '[EthicalSelfReflectionJourney] Update Completed Selected Dilemmas';
  constructor(public completedSelectedDilemmas: Set<string>) {}
}

export class UpdateESRJEthicalBehaviorResponses {
  static readonly type = '[EthicalSelfReflectionJourney] Update Ethical Behavior Responses';
  constructor(public ethicalBehaviorResponses: JourneyRatingData) {}
}

export class UpdateESRJEthicalMindfulResponses {
  static readonly type = '[EthicalSelfReflectionJourney] Update Ethical Mindful Responses';
  constructor(public ethicalMindfulResponses: JourneyRatingData) {}
}

export class UpdateESRJEthicalLeadershipResponses {
  static readonly type = '[EthicalSelfReflectionJourney] Update Ethical Leadership Responses';
  constructor(public ethicalLeadershipResponses: JourneyRatingData) {}
}

export class UpdateESRJEthicalLeadershipTextInputs {
  static readonly type = '[EthicalSelfReflectionJourney] Update Ethical Leadership Text Inputs';
  constructor(public ethicalLeadershipTextInputs: IEthicalInfluence) {}
}

export class UpdateESRJEthicalClimateResponses {
  static readonly type = '[EthicalSelfReflectionJourney] Update Ethical Climate Responses';
  constructor(public ethicalClimateResponses: JourneyRatingData) {}
}

export class UpdateESRJEthicalClimateTextInputs {
  static readonly type = '[EthicalSelfReflectionJourney] Update Ethical Climate Text Inputs';
  constructor(public ethicalClimateTextInputs: IEthicalInfluence) {}
}

export class UpdateESRJEthicalOwnDilemma {
  static readonly type = '[EthicalSelfReflectionJourney] Update Ethical Own Dilemma Responses';
  constructor(public ethicalOwnDilemma: IUserOwnDilemmaInput) {}
}

export class UpdateESRJEthicalSelectedDilemmaTextInput {
  static readonly type = '[EthicalSelfReflectionJourney] Update Ethical Selected Dilemma Text Responses';
  constructor(public ethicalSelectedDilemmaTextInput: IUserOwnDilemmaInput) {}
}

export class UpdateESRJEthicalImplementationIntention {
  static readonly type = '[EthicalSelfReflectionJourney] Update Ethical implementation intention';
  constructor(public ethicalImplementationIntention: IEthicalIntention) {}
}

export class UpdateESRJEthicalMentalContrast {
  static readonly type = '[EthicalSelfReflectionJourney] Update Ethical mental contrast';
  constructor(public ethicalMentalContrast: IEthicalContrast) {}
}

export class UpdateESRJEthicalGoalObjective {
  static readonly type = '[EthicalSelfReflectionJourney] Update Ethical goal objective';
  constructor(public ethicalGoalObjective: IEthicalGoal) {}
}

export class ResetESRJStatus {
  static readonly type = '[EthicalSelfReflectionJourney] Reset Ethical Self-Reflection Record';
}

export class ResetESRJSteps {
  static readonly type = '[EthicalSelfReflectionJourney] Reset Ethical Self-Reflection Steps';
  constructor(public newStepsArray: IEsrjCompletedStep[]) {}
}
